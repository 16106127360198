import { Box } from "@mui/material";
import { darken, lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { MouseEvent } from "react";
import IconSvg from "./img/icon.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFF",
    borderRadius: 26,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, .1)",
    color: "#262626",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    userSelect: "none",
    transition: theme.transitions.create(["box-shadow"], {
      easing: theme.transitions.easing.easeInOut,
      duration: "0.2s",
    }),
    "&:hover": {
      boxShadow: "0 4px 8px 0 rgba(0, 0, 0, .1)",
    },
  },
  icon: {
    flexGrow: 0,
    padding: 15,
    backgroundColor: "#fff",
    borderRadius: 26,
  },
  iconSmall: {
    flexGrow: 0,
    padding: 8,
    backgroundColor: "#fff",
  },
  svg: {
    display: "block",
    height: 18,
    width: 18,
  },
  label: {
    fontSize: "16px",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: "48px",
    paddingRight: 15,
  },
  small: {
    fontSize: "0.9rem",
    fontWeight: 700,
    lineHeight: "30px",
    letterSpacing: "0.21px",
  },
  dark: {
    color: "#fff",
    backgroundColor: "#4285F4",
    border: "1px solid transparent",
    maxWidth: "max-content",
    "&:hover": {
      backgroundColor: "#4285f4",
    },
    "&:active": {
      color: "#fff",
      backgroundColor: "#3367d6",
    },
    "& $label": {
      paddingLeft: 15,
      paddingRight: 24,
    }
  },
  disabled: {
    color: lighten("#262626", 0.25),
    backgroundColor: darken("#FFF", 0.25),
    pointerEvents: "none",
    "&:hover": {
      color: lighten("#262626", 0.25),
      backgroundColor: darken("#FFF", 0.25),
      boxShadow: "none",
    },
    "&:active": {
      color: lighten("#262626", 0.25),
      backgroundColor: darken("#FFF", 0.25),
    },
  },
}));

interface SocialButtonProps {
  label?: string;
  disabled?: boolean;
  dark?: boolean;
  small?: boolean;
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const GoogleButton: React.FC<SocialButtonProps> = ({
  label = "Log in with Google",
  disabled,
  dark,
  className,
  onClick,
  small,
}) => {
  const classes = useStyles();

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (onClick) onClick(e);
  };

  return (
    <Box
      aria-label={label}
      onClick={handleClick}
      className={clsx(
        classes.root,
        {
          [classes.dark]: dark,
          [classes.disabled]: disabled,
        },
        className
      )}
    >
      <Box
        className={clsx(classes.icon, "oidc-google-button-icon", {
          [classes.iconSmall]: small,
        })}
      >
        <IconSvg className={classes.svg} />
      </Box>
      <span
        className={clsx(classes.label, "oidc-google-button-label", {
          [classes.small]: small,
        })}
      >
        {label}
      </span>
    </Box>
  );
};

export default GoogleButton;
